
import { defineComponent, reactive, toRefs, computed, onMounted, ref } from "vue";
import TextButton from "@/components/atomics/button/TextButton.vue";
import ValidationTextField from "@/components/atomics/ValidationTextField.vue";
import { MemberRepository, RepositoryFactory, OnboardingRepository } from "@/lib/https";
import AfterLoginPage from "@/views/layouts/AfterLoginAdminPage.vue";
import ListItemActionDropdown from "@/components/atomics/list-item/ListItemActionDropdown.vue";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";
import Tag from "@/components/atomics/Tag.vue";
import Chip from "@/components/atomics/Chip.vue";
import Tabs from "@/components/atomics/Tabs.vue";
import ConfirmDeleteMemberPopup from "@/components/popups/ConfirmPopup.vue";
import usePopupControl from "@/lib/compositional-logic/usePopupControl";
import useNotificationControl from "@/lib/compositional-logic/useNotificationControl";
import useList from "@/lib/compositional-logic/useList";
import { AnonymousFunction } from "@/models/Function";
import { downloadCSV } from "@/lib/utility/downloadFile";
import { formatDate } from "@/lib/utility/dateTime";
import Vue3Popper from "@/components/atomics/Vue3Popper.vue";
import Dropdown from "@/components/atomics/dropdown/BaseDropdown.vue";
import BorderButton from "@/components/atomics/button/BorderButton.vue";
import { getUserRoleName } from "@/models/UserRole";

export default defineComponent({
  name: "Member",
  components: {
    TextButton,
    ValidationTextField,
    ConfirmDeleteMemberPopup,
    AfterLoginPage,
    ListItemActionDropdown,
    Tag,
    Chip,
    Tabs,
    Vue3Popper,
    Dropdown,
    BorderButton,
  },
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const route = useRoute();
    const { openNotification } = useNotificationControl();

    const companyName = route.params.companyName;

    const state = reactive({
      avatarBaseUrl: process.env.VUE_APP_API_CLOUD_URL,
      memberToAction: {
        id: "",
        title: "",
        message: "",
        btnText: "",
        action: AnonymousFunction,
      },
      currentTabIndex: 0,
      searchKeyword: "",
      searchKeywordInvite: "",
    });

    const tabTags = computed(() => [
      { name: t("member.participated"), total: totalLength.value },
      { name: t("member.invited"), total: inviteTotalLength.value },
    ]);

    const { popupState, openPopup } = usePopupControl();

    const actionDropdown = (
      member: {
        id: string;
        email: string;
        lastName: string;
        firstName: string;
        canDelete: string;
      },
      isInviting: any
    ) => [
      {
        action: () => {
          router.push({
            path: `/member/` + member.id,
            query: { isInviting: isInviting },
          });
        },
        icon: "icons/details.svg",
        text: t("member.buttonShowDetail"),
      },
      {
        action: () => {
          state.memberToAction.id = member.id;
          state.memberToAction.message = t("member.deleteMessage", {
            name: member.lastName + " " + member.firstName,
            email: member.email,
          });

          openPopup("confirmAction");
          (state.memberToAction.title = t("member.deleteTitle")),
            (state.memberToAction.btnText = t("common.delete")),
            (state.memberToAction.action = deleteMember);
        },
        icon: "icons/delete.svg",
        text: t("member.buttonDelete"),
        isDelete: true,
        disabled: !member.canDelete,
      },
    ];

    const inviteActionDropdown = (
      member: {
        id: string;
        email: string;
        name: string;
        canUpdate: string;
        canDelete: string;
      },
      isInviting: any
    ) => [
      {
        action: () => {
          router.push({
            path: `/member/` + member.id,
            query: { isInviting: isInviting },
          });
        },
        icon: "icons/details.svg",
        text: t("member.buttonShowDetail"),
      },
      {
        action: () => {
          state.memberToAction.id = member.id;
          state.memberToAction.message = t("member.reSendMessage", {
            name: member.name,
            email: member.email,
          });

          openPopup("confirmAction");
          (state.memberToAction.title = t("member.resendInvite")),
            (state.memberToAction.btnText = t("common.sendTo")),
            (state.memberToAction.action = pendingResend);
        },
        icon: "icons/re_invite.svg",
        text: t("member.resendInvite"),
        isDelete: true,
        disabled: !member.canUpdate,
      },
      {
        action: () => {
          state.memberToAction.id = member.id;
          state.memberToAction.message = t("member.cancelMessage", {
            name: member.name,
            email: member.email,
          });

          openPopup("confirmAction");
          (state.memberToAction.title = t("member.cancelSendTitle")),
            (state.memberToAction.btnText = t("member.buttonCancelSend")),
            (state.memberToAction.action = deleteInviteMember);
        },
        icon: "icons/stop.svg",
        text: t("member.optionCancelInvite"),
        isDelete: true,
        disabled: !member.canDelete,
      },
    ];

    const {
      list: memberListData,
      totalLength,
      loadMore,
      reloadList,
      setFilterKeyword,
      keyword,
      debouncedFilterList,
    } = useList({
      initialLoadList: async ({ pageSize, keyword }) =>
        await getMemberList({page: 1, pageSize, keyword, partner: partnerFilter.value, plan: planFilter.value, userRole: userRoleFilter.value}),
      loadMoreList: async ({ page, pageSize, keyword }) =>
        await getMemberList({page, pageSize, keyword, partner: partnerFilter.value, plan: planFilter.value, userRole: userRoleFilter.value}),
      filterList: async ({ keyword, pageSize }) =>
        await getMemberList({page:1, pageSize, keyword, partner: partnerFilter.value, plan: planFilter.value, userRole: userRoleFilter.value}),
    });

    const {
      getMemberList,
      downloadMemberCSV,
      deleteMember,
      getInviteMemberList,
      deleteInviteMember,
      pendingResend,
    } = RepositoryFactory.getRepository<MemberRepository>(MemberRepository);

    const {
      list: inviteMemberListData,
      totalLength: inviteTotalLength,
      loadMore: inviteLoadMore,
      reloadList: inviteReloadList,
      setFilterKeyword: setFilterKeywordInvite,
    } = useList({
      initialLoadList: async ({ pageSize, keyword }) =>
      await getInviteMemberList(1, pageSize, keyword),
      loadMoreList: async ({ page, pageSize, keyword }) =>
        await getInviteMemberList(page, pageSize, keyword),
      filterList: async ({ keyword, pageSize }) =>
        await getInviteMemberList(1, pageSize, keyword),
    });

    const onDownloadCSV = async () => {
      const response = await downloadMemberCSV({ isCsvExport: true, keyword: keyword.value, 
        partner: partnerFilter.value, plan: planFilter.value, userRole: userRoleFilter.value });

      downloadCSV(response, "member");
    };

    const onDeleteDone = async () => {
      if (state.currentTabIndex == 0) {
        await reloadList();
      } else {
        await inviteReloadList();
      }
      openNotification();
    };

    const setCompanyParameter = () => {
      if (companyName) {
        state.searchKeyword = companyName as string;
        state.searchKeywordInvite = companyName as string;
        setFilterKeyword(state.searchKeyword);
        setFilterKeywordInvite(state.searchKeywordInvite);
      }
    };

    onMounted(setCompanyParameter);

    const handleLoad = computed(() => {
      if (state.currentTabIndex == 0) {
        return loadMore;
      } else {
        return inviteLoadMore;
      }
    });

    const clickTitle = (id: string, isInviting: any) => {
      router.push({ path: `/member/` + id, query: { isInviting: isInviting }, });
    };


    const isShowFilter = ref(false);

    const partnerCompanyOptions = ref<Record<string, string>[]>([
      { id: "all", name: t("common.all") },
    ]);

    const planOptions = [
      { id: "all", name: t("common.all") },
      { id: "free", name: t("company.planFree") },
      { id: "lite", name: t("company.planLite") },
      { id: "standard", name: t("company.planStandard") },
      { id: "trial", name: t("company.planTrial") },
    ];

    const userRoleOptions = [
      { id: "all", name: t("common.all") },
      { id: "owner", name: t("member.role1") },
      { id: "manager", name: t("member.role2") },
      { id: "member", name: t("member.role3") },
    ];

    const partnerFilter = ref(partnerCompanyOptions.value[0].id);
    const planFilter = ref(planOptions[0].id);
    const userRoleFilter = ref(userRoleOptions[0].id);

    const { getPreferences } =
      RepositoryFactory.getRepository<OnboardingRepository>(
        OnboardingRepository
      );

    onMounted(async () => {
      const response = await getPreferences();
      if (response) {
        partnerCompanyOptions.value.push(...response);
      }
    });

    const clearFilter = () => {
      partnerFilter.value = partnerCompanyOptions.value[0].id;
      planFilter.value = planOptions[0].id;
      userRoleFilter.value = userRoleOptions[0].id;
      debouncedFilterList();
    };

    return {
      ...toRefs(state),
      tabTags,
      setFilterKeyword,
      actionDropdown,
      onDownloadCSV,
      popupState,
      memberListData,
      handleLoad,
      onDeleteDone,
      inviteMemberListData,
      inviteTotalLength,
      inviteLoadMore,
      inviteReloadList,
      setFilterKeywordInvite,
      inviteActionDropdown,
      clickTitle,
      formatDate,
      getUserRoleName,
      isShowFilter,
      partnerCompanyOptions,
      planOptions,
      userRoleOptions,
      partnerFilter,
      planFilter,
      userRoleFilter,
      clearFilter,
      debouncedFilterList,
    };
  },
});
