import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5e71dfec"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "sort-filter-section"
}
const _hoisted_2 = {
  key: 0,
  class: "filter-area"
}
const _hoisted_3 = { class: "filer-condition mr-2" }
const _hoisted_4 = { class: "popup-input-label" }
const _hoisted_5 = { class: "filer-condition mr-2" }
const _hoisted_6 = { class: "popup-input-label" }
const _hoisted_7 = { class: "filer-condition mr-2" }
const _hoisted_8 = { class: "popup-input-label" }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "section-header" }
const _hoisted_11 = { class: "title-column-symbol" }
const _hoisted_12 = { class: "title-company-name" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = ["onClick"]
const _hoisted_15 = { class: "text-ellipsis" }
const _hoisted_16 = { class: "created-at" }
const _hoisted_17 = { key: 1 }
const _hoisted_18 = ["onClick"]
const _hoisted_19 = { class: "text-ellipsis" }
const _hoisted_20 = { class: "created-at" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tabs = _resolveComponent("Tabs")!
  const _component_TextButton = _resolveComponent("TextButton")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_BorderButton = _resolveComponent("BorderButton")!
  const _component_ValidationTextField = _resolveComponent("ValidationTextField")!
  const _component_Vue3Popper = _resolveComponent("Vue3Popper")!
  const _component_Chip = _resolveComponent("Chip")!
  const _component_Tag = _resolveComponent("Tag")!
  const _component_ListItemActionDropdown = _resolveComponent("ListItemActionDropdown")!
  const _component_AfterLoginPage = _resolveComponent("AfterLoginPage")!
  const _component_ConfirmDeleteMemberPopup = _resolveComponent("ConfirmDeleteMemberPopup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AfterLoginPage, {
      class: "member-list",
      onScrolledToBottom: _ctx.handleLoad
    }, {
      "top-part": _withCtx(() => [
        _createVNode(_component_Tabs, {
          "onCurrentTab:value": _cache[0] || (_cache[0] = ($event: any) => (_ctx.currentTabIndex = $event)),
          "tab-tags": _ctx.tabTags
        }, null, 8, ["tab-tags"])
      ]),
      "right-part": _withCtx(() => [
        _withDirectives(_createVNode(_component_TextButton, {
          onOnClick: _ctx.onDownloadCSV,
          text: _ctx.$t('member.textButtonDownloadCsv'),
          icon: require('icons/download.svg')
        }, null, 8, ["onOnClick", "text", "icon"]), [
          [_vShow, _ctx.currentTabIndex == 0]
        ])
      ]),
      "left-top-part": _withCtx(() => [
        (_ctx.currentTabIndex == 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_TextButton, {
                text: _ctx.$t('common.advancedSearch'),
                icon: require('icons/filter.svg'),
                class: "mr-4",
                onOnClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isShowFilter = !_ctx.isShowFilter))
              }, null, 8, ["text", "icon"]),
              _createVNode(_Transition, null, {
                default: _withCtx(() => [
                  (_ctx.isShowFilter)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _createElementVNode("div", _hoisted_3, [
                          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("member.titlePartnerName")), 1),
                          _createVNode(_component_Dropdown, {
                            options: _ctx.partnerCompanyOptions,
                            "item-text": "name",
                            "item-value": "id",
                            modelValue: _ctx.partnerFilter,
                            "onUpdate:modelValue": [
                              _cache[2] || (_cache[2] = ($event: any) => ((_ctx.partnerFilter) = $event)),
                              _ctx.debouncedFilterList
                            ]
                          }, null, 8, ["options", "modelValue", "onUpdate:modelValue"])
                        ]),
                        _createElementVNode("div", _hoisted_5, [
                          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t("member.titlePlanName")), 1),
                          _createVNode(_component_Dropdown, {
                            options: _ctx.planOptions,
                            "item-text": "name",
                            "item-value": "id",
                            modelValue: _ctx.planFilter,
                            "onUpdate:modelValue": [
                              _cache[3] || (_cache[3] = ($event: any) => ((_ctx.planFilter) = $event)),
                              _ctx.debouncedFilterList
                            ]
                          }, null, 8, ["options", "modelValue", "onUpdate:modelValue"])
                        ]),
                        _createElementVNode("div", _hoisted_7, [
                          _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t("member.titleUserRole")), 1),
                          _createVNode(_component_Dropdown, {
                            options: _ctx.userRoleOptions,
                            "item-text": "name",
                            "item-value": "id",
                            modelValue: _ctx.userRoleFilter,
                            "onUpdate:modelValue": [
                              _cache[4] || (_cache[4] = ($event: any) => ((_ctx.userRoleFilter) = $event)),
                              _ctx.debouncedFilterList
                            ]
                          }, null, 8, ["options", "modelValue", "onUpdate:modelValue"])
                        ]),
                        _createVNode(_component_BorderButton, {
                          text: _ctx.$t('login.forgotPassCancelBtn'),
                          onOnClick: _ctx.clearFilter,
                          class: "mt-2"
                        }, null, 8, ["text", "onOnClick"])
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_9)),
        _withDirectives(_createVNode(_component_ValidationTextField, {
          "prefix-icon": require('icons/search-placeholder.svg'),
          "suffix-icon": require('icons/close.svg'),
          placeholder: _ctx.$t('member.titlePlaceHolderSearchBar'),
          "keep-error-space": false,
          name: "searchBar",
          value: _ctx.searchKeyword,
          "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => (_ctx.setFilterKeyword($event))),
          "is-delete-on-suffix": ""
        }, null, 8, ["prefix-icon", "suffix-icon", "placeholder", "value"]), [
          [_vShow, _ctx.currentTabIndex == 0]
        ]),
        _withDirectives(_createVNode(_component_ValidationTextField, {
          "prefix-icon": require('icons/search-placeholder.svg'),
          "suffix-icon": require('icons/close.svg'),
          placeholder: _ctx.$t('member.titlePlaceHolderSearchBar'),
          "keep-error-space": false,
          name: "searchBar",
          value: _ctx.searchKeywordInvite,
          "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => (_ctx.setFilterKeywordInvite($event))),
          "is-delete-on-suffix": ""
        }, null, 8, ["prefix-icon", "suffix-icon", "placeholder", "value"]), [
          [_vShow, _ctx.currentTabIndex == 1]
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.$t("member.titleColumnSymbol")), 1),
          _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.$t("member.titleColumnNextPayment")), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.$t("member.titleAcountType")), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.$t("member.titleColumnCreatedAt")), 1)
        ])
      ]),
      "left-bottom-part": _withCtx(() => [
        (_ctx.currentTabIndex == 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.memberListData, (member, index) => {
                return (_openBlock(), _createBlock(_component_ListItemActionDropdown, {
                  key: index,
                  "head-icon": 
            member.profilePicture
              ? _ctx.avatarBaseUrl + member.profilePicture
              : require('icons/default-avatar.svg')
          ,
                  "is-avatar-head": "",
                  actions: _ctx.actionDropdown(member, false),
                  "has-divider": ""
                }, {
                  "title-primary": _withCtx(() => [
                    _createVNode(_component_Vue3Popper, {
                      content: `${member.lastName} ${member.firstName}`,
                      class: "text-ellipsis"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("p", {
                          onClick: ($event: any) => (_ctx.clickTitle(member.id, false))
                        }, _toDisplayString(`${member.lastName} ${member.firstName}`), 9, _hoisted_14)
                      ]),
                      _: 2
                    }, 1032, ["content"]),
                    _createVNode(_component_Vue3Popper, {
                      content: member.phoneNumber,
                      class: "chip"
                    }, {
                      default: _withCtx(() => [
                        (member.phoneNumber)
                          ? (_openBlock(), _createBlock(_component_Chip, {
                              key: 0,
                              text: member.phoneNumber,
                              dense: ""
                            }, null, 8, ["text"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1032, ["content"])
                  ]),
                  "title-secondary": _withCtx(() => [
                    _createElementVNode("p", _hoisted_15, _toDisplayString(member.email), 1)
                  ]),
                  body: _withCtx(() => [
                    _createVNode(_component_Vue3Popper, {
                      content: member.companyName,
                      class: "company-name"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Tag, {
                          text: member.companyName
                        }, null, 8, ["text"])
                      ]),
                      _: 2
                    }, 1032, ["content"]),
                    _createVNode(_component_Vue3Popper, {
                      content: _ctx.$t(_ctx.getUserRoleName(member.role)),
                      class: "account-type"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Tag, {
                          text: _ctx.$t(_ctx.getUserRoleName(member.role))
                        }, null, 8, ["text"])
                      ]),
                      _: 2
                    }, 1032, ["content"]),
                    _createElementVNode("div", _hoisted_16, [
                      _createElementVNode("p", null, _toDisplayString(member.createdAt ? _ctx.formatDate(member.createdAt, "YYYY/MM/DD") : member.createdAt), 1)
                    ])
                  ]),
                  _: 2
                }, 1032, ["head-icon", "actions"]))
              }), 128))
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_17, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.inviteMemberListData, (member, index) => {
                return (_openBlock(), _createBlock(_component_ListItemActionDropdown, {
                  key: index,
                  "head-icon": 
            member.profilePicture
              ? _ctx.avatarBaseUrl + member.profilePicture
              : require('icons/default-avatar.svg')
          ,
                  "is-avatar-head": "",
                  actions: _ctx.inviteActionDropdown(member, true),
                  "has-divider": ""
                }, {
                  "title-primary": _withCtx(() => [
                    _createVNode(_component_Vue3Popper, {
                      content: `${member.name}`,
                      class: "text-ellipsis"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("p", {
                          onClick: ($event: any) => (_ctx.clickTitle(member.id, true))
                        }, _toDisplayString(`${member.name}`), 9, _hoisted_18)
                      ]),
                      _: 2
                    }, 1032, ["content"]),
                    _createVNode(_component_Vue3Popper, {
                      content: member.phoneNumber,
                      class: "chip"
                    }, {
                      default: _withCtx(() => [
                        (member.phoneNumber)
                          ? (_openBlock(), _createBlock(_component_Chip, {
                              key: 0,
                              text: member.phoneNumber,
                              dense: ""
                            }, null, 8, ["text"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1032, ["content"])
                  ]),
                  "title-secondary": _withCtx(() => [
                    _createElementVNode("p", _hoisted_19, _toDisplayString(member.email), 1)
                  ]),
                  body: _withCtx(() => [
                    _createVNode(_component_Vue3Popper, {
                      content: member.companyName,
                      class: "company-name"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Tag, {
                          text: member.companyName
                        }, null, 8, ["text"])
                      ]),
                      _: 2
                    }, 1032, ["content"]),
                    _createVNode(_component_Vue3Popper, {
                      content: _ctx.$t(_ctx.getUserRoleName(member.role)),
                      class: "account-type"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Tag, {
                          text: _ctx.$t(_ctx.getUserRoleName(member.role))
                        }, null, 8, ["text"])
                      ]),
                      _: 2
                    }, 1032, ["content"]),
                    _createElementVNode("div", null, [
                      _createElementVNode("p", _hoisted_20, _toDisplayString(member.createdAt ? _ctx.formatDate(member.createdAt, "YYYY/MM/DD") : "-"), 1)
                    ])
                  ]),
                  _: 2
                }, 1032, ["head-icon", "actions"]))
              }), 128))
            ]))
      ]),
      _: 1
    }, 8, ["onScrolledToBottom"]),
    (_ctx.popupState.confirmAction)
      ? (_openBlock(), _createBlock(_component_ConfirmDeleteMemberPopup, _mergeProps({ key: 0 }, _ctx.memberToAction, { onOnDone: _ctx.onDeleteDone }), null, 16, ["onOnDone"]))
      : _createCommentVNode("", true)
  ], 64))
}